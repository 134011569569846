const getAppendQueryDirective = (queryIncludes) => {
  return {
    bind (el) {
      if (el.tagName.toLowerCase() !== 'a' || !el.href) {
        console.warn('Element should be <a> tag')
        return
      }

      const getSearchParams = () => {
        if (!window) return
        const searchParams = new URLSearchParams(window.location.search)
        const params = []
        for (const param of searchParams) {
          params.push(param)
        }
        return Object.fromEntries(params)
      }

      const queryParameters = getSearchParams()

      const queryParametersToKeep = queryIncludes
        ? Object.fromEntries(
          Object.entries(queryParameters)
            .filter(([key, _]) => queryIncludes.find(include => key.includes(include)))
        )
        : queryParameters

      if (Object.keys(queryParametersToKeep).length > 0) {
        const originalHref = el.getAttribute('href')
        const separator = originalHref.includes('?') ? '&' : '?'

        const queryString = Object.entries(queryParametersToKeep)
          .map(([key, value]) => `${key}=${value}`)
          .join('&')

        const newHref = `${originalHref}${separator}${queryString}`
        el.setAttribute('href', newHref)
      }
    }
  }
}

export default getAppendQueryDirective
